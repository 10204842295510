import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import EstateVisualContainer from "@/components/estates/visual/estateVisualContainer"

import wiz1 from "@/images/puck/z16-2-0001-tablet.jpg"
import wiz1Large from "@/images/puck/z16-2-0001.jpg"
import wiz2 from "@/images/z-22/PUCK_NEW_UJECIE_1v3v2-tablet.jpg"
import wiz2Large from "@/images/z-22/PUCK_NEW_UJECIE_1v3v2.jpg"
import wiz3 from "@/images/puck/z16-2-0003-tablet.jpg"
import wiz3Large from "@/images/puck/z16-2-0003.jpg"
import wiz4 from "@/images/puck/z16-2-0004-tablet.jpg"
import wiz4Large from "@/images/puck/z16-2-0004.jpg"
import wiz5 from "@/images/z-20/3.2_tablet.jpg"
import wiz5Large from "@/images/z-20/3.2.jpg"
import wiz6 from "@/images/z-20/4_tablet.jpg"
import wiz6Large from "@/images/z-20/4.jpg"

const PuckVisual = ({ intl }) => {
  const sections = [
    {
      title: intl.formatMessage({
        id: "offer.janowo.visual.title",
      }),
      items: [
        {
          title: "Kameralna i elegancka zabudowa",
          text: `Osiedle Pogodne to zamknięte Osiedle, którego <strong>kameralny charakter</strong> sprosta oczekiwaniom najbardziej wymagających mieszkańców. Nowoczesna architektura z akcentem w postaci klasycznego klinkieru doskonale harmonizuje się z otoczeniem, podkreślając ponadczasowy charakter inwestycji.`,
          img: wiz1,
          imgLarge: wiz1Large,
          divClass: "wiz-img--tall",
          imgClass: "cover",
        },
        {
          title: "Przestronne tarasy z widokiem na morze",
          text: `Relaks i ukojenie przyniesie odpoczynek <strong>na prywatnym tarasie</strong>, z którego widok rozpościera się <strong>na morze (Zatokę Pucką)</strong>. Doskonały widok i spokojna okolica zapewnią komfort i wypoczynek`,
          img: wiz2,
          imgLarge: wiz2Large,
          divClass: "wiz-img--tall",
          imgClass: "cover",
        },

        {
          title: "Przestronne mieszkania i apartamenty z ogródkami",
          text: `Projektanci Osiedla Pogodnego zadbali o każdy szczegół inwestycji. <strong>Wysoka jakość wykończeń,</strong> praktyczny i przemyślany rozkład pomieszczeń, a także nowoczesne i z dbałością zaprojektowane części wspólne były priorytetem podczas tworzenia inwestycji. Każdy lokal posiada prywatny taras, przestronny balkon lub ogródek. Wszystko po to, aby zapewnić najwyższy komfort mieszkańcom.`,
          img: wiz4,
          imgLarge: wiz4Large,
          divClass: "wiz-img--tall",
          imgClass: "cover",
        },
        {
          title: "Wykończenie pod klucz w 10 tygodni",
          text: `Z myślą o zaoszczędzeniu Państwa czasu i eliminacji stresu związanego z remontem, stworzyliśmy kompleksową usługę, która obejmuje dobór i realizację wykończeń materiałowych w lokalach. W ramach tej usługi zajmujemy się instalacją drzwi wewnętrznych, pełnej armatury oraz przyborów sanitarnych do łazienki. Dzięki temu proces wykańczania mieszkań staje się prostszy i szybszy.`,
          img: wiz3,
          imgLarge: wiz3Large,
          divClass: "wiz-img--tall",
          imgClass: "cover",
        },
        {
          title: "Części wspólne wysokiej jakości",
          text: `Podczas projektowania inwestycji duży nacisk kładliśmy na estetykę oraz wysoką funkcjonalność części wspólnych.`,
          img: wiz5,
          imgLarge: wiz5Large,
          divClass: "wiz-img--tall",
          imgClass: "cover",
        },
        {
          title: "Przestronne strefy wejścia",
          text: `Przytulna i klasyczna strefa wejścia utrzymana w stonowanej kolorystyce oraz przestronne korytarze z gustownymi portalami drzwiowymi tworzą elegancki styl.`,
          img: wiz6,
          imgLarge: wiz6Large,
          divClass: "wiz-img--tall",
          imgClass: "cover",
        },
      ],
    },
    /*  {
      title: "Zmiany aranżacyjne i wykończenia pod klucz",
      items: [
        {
          text: `Wiemy, że wykończenie mieszkania często jest nie lada wyzwaniem, dlatego bazując na wieloletnim doświadczeniu naszych architektów i współpracy ze sprawdzonymi wykonawcami stworzyliśmy dział aranżacji i wykończeń pod klucz.<br><br>Jeśli potrzebujesz wprowadzić zmiany w układzie swojego mieszkania tak aby spełniało Twoje potrzeby, zaraz po zakupie lokalu, wspólnie z naszym architektem możesz dokonać niezbędnych poprawek.`,
          img: wiz9,
        },
        {
          text: `Możesz również skorzystać z pakietów wykończeniowych oszczędzając swój czas i pieniądze. To doskonałe rozwiązanie dzięki któremu zamieszkasz w pięknych i funkcjonalnych wnętrzach. Współpracując z naszym architektem stworzysz przestrzeń spełniającą Twoje oczekiwania.`,
          img: wiz10,
          imgStyle: { marginTop: "-42%" },
        },
      ],
    },*/
  ]

  return <EstateVisualContainer sections={sections} />
}
export default injectIntl(PuckVisual)
